/**
 * Some subset of browsers and their versions do not support SameSite or SameSite=None.
 *
 * To address this edge case, we must parse through the user agent string to determine if it is likely safe to send.
 *
 * Note that this won't be 100% accurate about if a client is incompatible. User agents vary wildly.
 *
 * Derived from pseudo-code about this topic: https://www.chromium.org/updates/same-site/incompatible-clients
 */

/**
 *
 * @param userAgent {string}
 * @returns {boolean} False only if the provided user agent is known to be incompatible. True otherwise.
 */
export function shouldSendSameNone(userAgent) {
  if (userAgent) {
    return !isSameSiteNoneIncompatible(userAgent);
  }

  return true;
}

// Known incompatible clients

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function isSameSiteNoneIncompatible(userAgent) {
  return (
    hasWebKitSameSiteBug(userAgent) ||
    dropsUnrecognizedSameSiteCookies(userAgent)
  );
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function hasWebKitSameSiteBug(userAgent) {
  return (
    isIosVersion(12, userAgent) ||
    (isMacosxVersion(10, 14, userAgent) &&
      (isSafari(userAgent) || isMacEmbeddedBrowser(userAgent)))
  );
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function dropsUnrecognizedSameSiteCookies(userAgent) {
  if (isUcBrowser(userAgent)) {
    return !isUcBrowserVersionAtLeast(12, 13, 2, userAgent);
  }
  return (
    isChromiumBased(userAgent) &&
    isChromiumVersionAtLeast(51, userAgent) &&
    !isChromiumVersionAtLeast(67, userAgent)
  );
}

// Incompatible client regexes

/**
 *
 * @param major {number}
 * @param userAgent {string}
 * @returns {boolean}
 */
function isIosVersion(major, userAgent) {
  const regex = /\(iP.+; CPU .*OS (\d+)[_\d]*.*\) AppleWebKit\//;
  // Extract digits from first capturing group.
  const matches = userAgent.match(regex);

  return matches && matches[1] === major.toString();
}

/**
 *
 * @param major {number}
 * @param minor {number}
 * @param userAgent {string}
 * @returns {boolean}
 */
function isMacosxVersion(major, minor, userAgent) {
  const regex = /\(Macintosh;.*Mac OS X (\d+)_(\d+)[_\d]*.*\) AppleWebKit\//;
  // Extract digits from first and second capturing groups.
  const matches = userAgent.match(regex);

  return (
    matches &&
    matches[1] === major.toString() &&
    matches[2] === minor.toString()
  );
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function isSafari(userAgent) {
  const safariRegex = /Version\/.* Safari\//;
  const matchFound = safariRegex.test(userAgent);
  return matchFound && !isChromiumBased(userAgent);
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function isMacEmbeddedBrowser(userAgent) {
  const regex = /^Mozilla\/[.\d]+ \(Macintosh;.*Mac OS X [_\d]+\) AppleWebKit\/[.\d]+ \(KHTML, like Gecko\)$/;
  return regex.test(userAgent);
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function isChromiumBased(userAgent) {
  const regex = /Chrom(e|ium)/;
  return regex.test(userAgent);
}

/**
 *
 * @param major {number}
 * @param userAgent {string}
 * @returns {boolean} True if the user agent version is greater than or equal to the major. False otherwise.
 */
function isChromiumVersionAtLeast(major, userAgent) {
  const regex = /Chrom[^ /]+\/(\d+)[.\d]* /;
  // Extract digits from first capturing group.
  const matches = userAgent.match(regex);
  if (matches) {
    const version = parseInt(matches[1], 10);
    return version >= major;
  }

  return false;
}

/**
 *
 * @param userAgent {string}
 * @returns {boolean}
 */
function isUcBrowser(userAgent) {
  const regex = /UCBrowser\//;
  return regex.test(userAgent);
}

/**
 *
 * @param major {number}
 * @param minor {number}
 * @param build {number}
 * @param userAgent {string}
 * @returns {boolean}
 */
function isUcBrowserVersionAtLeast(major, minor, build, userAgent) {
  const regex = /UCBrowser\/(\d+)\.(\d+)\.(\d+)[.\d]* /;
  // Extract digits from three capturing groups.
  const matches = userAgent.match(regex);
  if (matches) {
    const majorVersion = parseInt(matches[1], 10);
    const minorVersion = parseInt(matches[2], 10);
    const buildVersion = parseInt(matches[3], 10);
    if (majorVersion !== major) {
      return majorVersion > major;
    }
    if (minorVersion !== minor) {
      return minorVersion > minor;
    }
    return buildVersion >= build;
  }

  return true;
}
