<template>
  <div id="app">
    <div class="section">
      <main class="container box is-centered">
          <div id="header">
              <div class="logo-white">
                  <img
                          src="../resources/logo.png"
                          alt="Dun and Bradstreet Logo"
                  />
              </div>
          </div>
          <section class="section">
            <p class="content">
                Click the button below to opt out of Dun & Bradstreet (D&B) Visitor Intelligence tracking. The opt-out will write a cookie to your browser that exempts it from being tracked and matched on a go-forward basis. If you are active on multiple browsers or devices, this action will need to be performed on each device.
            </p>
              <button
                      class="content button is-primary"
                      @click.prevent="writeCookie"
              >
                  Opt Out
              </button>
            <p class="content">
                Visitor Intelligence provides information on website traffic of businesses and business employees to a company website owner for marketing purposes using a combination of IP address-based lookups and cookie syncing. Click here to learn more about <a href="https://www.dnb.com/products/marketing-sales/dnb-visitor-intelligence.html">Visitor Intelligence</a>.
            </p>
              <p class="content">
                  For more information on Dun & Bradstreet's privacy and cookie policies, please refer to our privacy and cookie notices:
              </p>
              <ul class="content">
                  <li>
                      Privacy Notice for the United States: <a href="https://www.dnb.com/utility-pages/privacy-policy.html">https://www.dnb.com/utility-pages/privacy-policy.html</a>
                  </li>
                  <li>
                      Privacy Notice for Canada: <a href="https://www.dnb.com/ca-en/utility-pages/privacy-policy.html">https://www.dnb.com/ca-en/utility-pages/privacy-policy.html</a>
                  </li>
                  <li>
                      Privacy Notice for the European Union: <a href="https://www.dnb.co.uk/utility-pages/privacy-policy.html">https://www.dnb.co.uk/utility-pages/privacy-policy.html</a>
                  </li>
                  <li>
                      Cookie Policy for the European Union: <a href="https://www.dnb.co.uk/utility-pages/cookie-policy.html">https://www.dnb.co.uk/utility-pages/cookie-policy.html</a>
                  </li>
              </ul>
              <p class="content">
                  Please contact us at <a href="mailto:supportAS@dnb.com">supportAS@dnb.com</a> if you have any further questions or concerns. We will be happy to assist.
              </p>
          </section>
      </main>
    </div>
  </div>
</template>

<script>
import BField from "buefy/src/components/field/Field";
import BSelect from "buefy/src/components/select/Select";
import { shouldSendSameNone } from "./userAgent";

export default {
  name: "App",
  components: { BSelect, BField },
  data() {
    return {
      cookieName: "D41C",
    };
  },
  computed: {
  },
  methods: {
    writeCookie() {
      const secure = "; Secure";
        let cookie="";
        const cookieValue = "";
        const date = new Date();
        const days = 365;
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `; expires=${date.toUTCString()}`;
        const path = "; path=/";
        const { host } = window.location;

      let sameSite = "";
      if (shouldSendSameNone(navigator.userAgent)) {
        sameSite = "; SameSite=None";
      } else {
        console.info("Omitting SameSite=none based on request's user agent.");
      }

        let domainParts = host.split(".");
        if (domainParts.length > 2) {
          domainParts = host.split(".");
          domainParts.shift();
          const domain = `; domain=.${domainParts.join(".")}`;
          cookie = `${
            this.cookieName
            }=${expires}${path}${domain}${sameSite}${secure}`;
        } else {
          const domain = `; domain=.${host}`;
          cookie = `${this.cookieName}=${cookieValue}${expires}${path}${domain}${sameSite}${secure}`;
        }

        document.cookie = cookie;
      if (document.cookie.includes(cookieValue)) {
        this.$toast.open("You are now opted out");
      } else {
        this.$toast.open("Something went wrong. Failed to opt out.");
      }
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
  background-color: lightgray;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
